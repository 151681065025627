.wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 76px;
  position: relative;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    width: 98px;
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 0;
}

.links {
  display: flex;
  gap: 40px;
}

.link {
  color: #8B1026;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-transform: capitalize;
  text-decoration: none;
}

.btn {
  text-decoration: none;
  font-size: 16px;
  line-height: 42px;
  width: 113px;
  height: 42px;
  padding: 0;
}

.hamburger {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  flex-direction: column;
  width: 40px;
  height: 40px;
  background: #8B1026;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 20px;
  border: none;
  box-shadow: none;
  outline: none;
  transform: translateY(-50%);
  z-index: 10;

  span {
    width: 30px;
    height: 3px;
    background: #fff;
    border-radius: 3px;
    transition: .3s;
  }

  &.active {
    span {
      &:first-of-type {
        transform: rotate(45deg) translate(7px, 6px);
      }

      &:nth-of-type(2) {
        opacity: 0;
        transform: translateX(100%);
      }

      &:last-of-type {
        transform: rotate(-45deg) translate(6px, -6px);
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .logo img {
    width: 66px;
  }

  .nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    flex-direction: column;
    padding: 150px 50px 100px;
    background: #8B1026;
    transition: .3s;
    transform: translateY(-100%);
    z-index: 5;

    &.open {
      transform: translateY(0);
    }
  }

  .links {
    flex-direction: column;
    text-align: center;
  }

  .link {
    color: #fff;
  }

  .btn {
    background: #fff;
    color: #8B1026;
    width: 100%;

    &:hover {
      background: #f1f1f1;
    }
  }

  .hamburger {
    display: flex;
  }
}