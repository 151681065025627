@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Cormorant Garamond', serif;
  background: #FEF8F4;
}

.container {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: 1310px;
}

img {
  max-width: 100%;
  object-fit: cover;
}

.title {
  font-weight: 700;
  font-size: 50px;
  line-height: 65px;
  text-align: center;
  color: #8B1026;
  margin-bottom: 28px;

  &Lines {
    vertical-align: middle;

    &:first-of-type {
      margin-right: 30px;
    }
    &:last-of-type {
      margin-left: 30px;
    }
  }
}

.text {
  color: #000;
  font-size: 30px;
  font-weight: 400;
  line-height: 48px;
}

.btn {
  font-family: 'Cormorant Garamond', serif;
  color: #FFF;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  line-height: 131%;
  border-radius: 8px;
  background: #8B1026;
  padding: 10px 90px;
  border: none;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
  transition: .15s;

  &:hover {
    background: #a2102b;

  }
}

.hero {
  padding: 205px 0 86px;
  position: relative;

  &Img1 {
    position: absolute;
    left: 0;
    bottom: -30px;
  }

  &Img2 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  &Wrapper {
    display: flex;
    flex-direction: column;
  }

  &Title {
    font-size: 60px;
    line-height: 131%;
  }

  &Text {
    margin: 62px auto 26px;
    text-align: center;
    max-width: 750px;
    position: relative;
    color: #311D35;
    font-size: 30px;
    font-style: italic;
    font-weight: 500;
    line-height: 44px;
    padding: 20px 0;
  }

  &Quote1 {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  &Quote2 {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(100%) scaleX(-1);
  }

  &Doctor {
    color: #8B1026;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    line-height: 131%;
    margin-bottom: 32px;

    &Lines {
      width: 20px;
    }
  }

  &Btn {
    margin: 0 auto;
  }
}

.download {
  padding: 110px 0 128px;
  background: linear-gradient(90deg,#FEF8F4 0,#FEF8F4 46%,#fff 0,#fff);

  &Wrapper {
    display: flex;
    align-items: center;
    gap: 100px;
  }

  &Main {
    flex: 1 1 0;
    min-width: 50%;
  }

  &Title {
    text-align: left;
    margin-bottom: 28px;
  }

  &Btns {
    margin-top: 33px;
    display: flex;
    gap: 16px;
  }
  
  &Btn {
    width: 212px;
    padding: 10px 0;
    
    svg  {
      transform: scale(1.5);

      path {
        fill: #fff;
      }
    }
  }
}

.discover {
  padding: 168px 0 104px;
  background: #fff;

  &Wrapper {
    display: flex;
    align-items: center;
    gap: 80px;
  }

  &Main {
    flex: 1 1 0;
  }

  &Title {
    text-align: left;
  }

  &Btns {
    margin-top: 32px;
    display: flex;
    align-items: center;
    gap: 50px;
  }

  &Link {
    color: #92001A;
    font-size: 25px;
    font-weight: 700;
    line-height: 131%;
    text-decoration-line: underline;
  }

  &Slider {
    flex: 1 1 0;
    max-width: 650px;
    background: #F3E9E8;
    padding: 38px 70px 42px;
  }

  &SlideImg {
    width: 100%;
  }
}

:global(.swiper-pagination) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin: 30px auto 0;
  height: 14px;
}

:global(.swiper-pagination-bullet) {
  display: flex;
  width: 8px;
  height: 8px;
  background: #8B1026;
  border-radius: 50%;
  cursor: pointer;
  transition: .3s;
}

:global(.swiper-pagination-bullet-active) {
  width: 14px;
  height: 14px;
  background: transparent;
  border: 3px solid #8B1026;
}

.scanInput {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

@media screen and (max-width: 1024px) {
  .hero {
    padding-top: 120px;
  }

  .download {
    background: #FEF8F4;

    &Wrapper {
      flex-direction: column-reverse;
      gap: 40px;
      padding: 0;

      & > div:first-of-type {
        background: #F3E9E8;
        width: 100%;
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &Main {
      padding: 0 20px;
    }
  }

  .discover {
    padding-top: 110px;

    &Wrapper {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 834px) {
  .title {
    &Lines {
      display: none;
    }
  }

  .hero {
    padding: 100px 0 50px;

    &Title {
      font-size: 50px;
    }

    &Text {
      max-width: 90%;
      margin: 32px auto 26px;
    }

    &DoctorLines {
      display: none;
    }
  }
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 32px;
    line-height: 42px;
  }

  .text {
    font-size: 16px;
    line-height: 26px;
  }

  .btn {
    font-size: 16px;
    line-height: 21px;
    padding: 15px;
    width: 272px;
  }

  .hero {
    padding: 50px 0 70px;

    &Img1 {
      width: 70%;
      z-index: -1;
    }

    &Img2 {
      width: 70%;
      top: -100px;
    }

    &Title {
      margin-bottom: 32px;
    }

    &Text {
      max-width: 100%;
      font-size: 16px;
      font-weight: 500;
      line-height: 33px;
      padding: 0;
      margin: 20px auto 36px;
    }

    &Quote1 {
      width: 20px;
      left: 0;
      top: 0;
      transform: translateY(-100%);
    }

    &Quote2 {
      width: 20px;
      right: 0;
      bottom: 0;
      transform: translateY(100%) scaleX(-1);
    }

    &Doctor {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }

  .download {
    padding: 72px 0 64px;

    &Wrapper {
      gap: 18px;
    }

    &Title {
      margin-bottom: 16px;
      text-align: center;
    }

    &Btns {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  }

  .discover {
    padding: 48px 0;

    &Wrapper {
      gap: 50px;
    }

    &Title {
      text-align: center;
    }

    &Btns {
      flex-direction: column;
      gap: 26px;
    }

    &Slider {
      max-width: 100%;
      padding: 20px;
    }
  }

  :global(.swiper-pagination) {
    margin-top: 20px;
  }
}